@import './screenSizes.scss';
@import './colors.scss';

@mixin list-of-content {
    width: 1300px;
    max-width: 90%;
    margin-top: 3%;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: $tablet) {
        width: 100%;
        justify-content: center;
    }
}

@mixin container($direction: column, $align-items: center) {
    min-height: 500px;
    width: 100%;
    padding: 5% 0;

    display: flex;
    flex-wrap: wrap;
    flex-direction: $direction;
    align-items: $align-items;
    background: white;
    background: $bg;
    @media screen and (max-width: $smallDesktop) {
        min-height: 300px;
    }
}

@mixin doctor-profile-card-container($align-items: flex-start) {
    width: 100%;
    padding: 32px;
    border-radius: 4px;
    background: white;

    display: flex;
    flex-direction: column;
    align-items: $align-items;

    @media screen and (max-width: $tablet) {
        padding: 24px;
    }
}

@mixin doctor-profile-card-content {
    width: 100%;
    padding-right: 60px;
    text-align: right;

    @media screen and (max-width: $tablet) {
        padding-right: 0;
    }
}

@mixin outLinedPrimary {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 50px;
}

@mixin outLinedWhite {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 50px;
}

@mixin gradient {
    background: transparent linear-gradient(270deg, $secondary 0%, $primary 100%) 0% 0% no-repeat padding-box;
    color: white;
    border: none;
    border-radius: 50px;
}

@mixin solidPrimary {
    background-color: $primary;
    color: white;
    border: none;
    border-radius: 50px;
}
@mixin solidWhite {
    background-color: white;
    color: $primary;
    border: none;
    border-radius: 50px;
}
@mixin light {
    background-color: $border;
    color: $primary;
    border: none;
    border-radius: 50px;
}

@mixin redGradient {
    // background: transparent linear-gradient(270deg, $secondary 0%, $primary 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(90deg, #fc485499 0%, #fc4854 100%) 0% 0% no-repeat padding-box;
    color: white;
    border: none;
    border-radius: 50px;
}

@mixin lightRed {
    color: #fc4854;
    background: transparent;
    border: none;
    border-radius: 50px;
}
