@import './styles/colors.scss';
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;700&display=swap');
.app-container {
    text-align: center;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    direction: rtl !important;

    background: #f4fcfd;

    color: $text-dark;
    font-family: 'Tajawal', sans-serif !important;
    direction: rtl;

    a {
        color: inherit;
        text-decoration: none !important;
    }

    pre {
        direction: rtl;
        font-family: 'Tajawal', sans-serif !important;
    }

    hr {
        opacity: 1;
        text-align: center !important;
        margin: auto;
    }
}
