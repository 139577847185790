$primary: #31c2e1;
$secondary: #44a8de;

$gradient: linear-gradient(90deg, #31c2e1 0%, #44a8de 100%);

$bg: #f8fefe;

$border: #f0fbfd;

$text-dark: #434343;

$text-light: #808080;
