// .home-container {
//     border: solid;
//     min-height: 100vh;
// }

@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';
@import '../../styles/colors.scss';

.home-container {
    @include container;

    padding-top: 0;
    .forget-password-content {
        @include list-of-content;
        width: 1600px;
        align-items: flex-start;
        margin-top: 10rem;
        .data-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 60%;
            max-width: 570px;
            H4 {
                font-weight: 400;
                margin-bottom: 1rem;
            }

            P {
                color: $text-light;
                margin-bottom: 2rem;
                text-align: right;
            }

            P[other='error-text'] {
                color: #fc4854;
                margin-bottom: 0;
            }
            // input {
            //     max-width: 470px;
            // }
            button {
                margin-top: 2.5rem;
            }
        }
        .password-img {
            width: 406px;
            width: 35%;
            max-width: 406px;
        }
    }
}
.error-container {
    // border: solid;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 100px;
    }
    P {
        margin-top: 2rem;
    }
}
@media screen and (max-width: $smallDesktop) {
    .home-container {
        .forget-password-content {
            margin-top: 5rem;

            .data-container {
                width: 50%;
                max-width: auto;
            }
            .password-img {
                width: 35%;
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .home-container {
        .forget-password-content {
            .data-container {
                order: 2;
                width: 90%;
            }
            .password-img {
                width: 60%;
            }
        }
    }
}
